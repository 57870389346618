:root {
	--border-color: #004a6533;
	--primary-color: #3998ff;
	--secondary-color: #283241;
	--background-color: #000000;
	--modal-background-color: #101828;
	--swap-container-background-color: #10182800;
	--swap-container-border-color: #1d293900;
	--input-card-pay-color: #30475b;
	--input-card-receive-color: #30475b;
	--input-card-color: #28303f;
	--input-token-color: #1d2939;
	--change-direction-background-color: #3998ff;
	--change-direction-icon-color: #ffffff;
	--light-shade-color: #1e3547;
	--slippage-box-color: #d1d1e0;
	--text-black-color: #ffffff;
	--text-white-color: #ffffff;
	--text-fade-color: #6b7280;
	--skeleton-shine-color: #585959;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
	overscroll-behavior: none;
	user-select: none;
	touch-action: none;
	position: static;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.toast-wrapper {
	/* max-width: 50vw !important; */
	right: 0 !important;
	top: 100px !important;
	left: auto !important;
	width: fit-content !important;
	.Toastify__toast-theme--colored {
		margin: 8px 0 4px 0 !important;
		border-radius: 8px;
		min-height: 0 !important;
	}
}

#mission {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
}
#mission::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}
#campaign-details {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
}
#campaign-details::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}
* {
	scrollbar-width: thin;
	scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

#lottery-otp input {
	color: #15b643 !important;
	border: 1px solid #15b643 !important;
}

.mytonswap-app .container {
	margin: 40px auto !important;
}

.mytonswap-app .popover {
	top: 40% !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
}

.mytonswap-app .selection-box-container {
	font-size: 18px !important;
}
